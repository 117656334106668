import TranslateWrapper from "@components/TranslateWrapper"
import Profile from "@modules/Profile"
import cx from "classnames"
import React, { useEffect, useState } from "react"

import styles from "../style.module.less"

const ProfilePage = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <div
        className={cx(
          "mc center profilePageContext mb72",
          styles.profilePageContext
        )}
      >
        <Profile empty={true} blocked={true} />
      </div>
    </TranslateWrapper>
  )
}

export default ProfilePage
