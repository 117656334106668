import PostCard from "@components/PostCard"
import EmptyEssay from "@modules/Essay/Site/components/EmptyEssay"
import _map from "lodash/map"
import React from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"
import { Row, Col } from "antd"
import cx from "classnames"
import { useMedia } from "react-use-media"
import styles from "./style.module.less"

const PostFeed = ({
  data,
  handleLikeUnlike,
  isProfile,
  nickname,
  setRefreshProfile,
  isFeed,
  isFeedEmpty,
}) => {
  const isDesktop = useMedia({ minWidth: 1025 })
  const isLapTop = useMedia({ minWidth: 1419 })
  const colConfig = cx(styles.marginCol, {
    squareMobile: !isDesktop,
    squareDesktop: isDesktop,
  })

  if (data && !data.length) {
    return (
      <div className="mb48">
        <EmptyEssay isFeed={isFeed} isFeedEmpty={isFeedEmpty} />
      </div>
    )
  }

  if (isDesktop) {
    return (
      <Row className={cx("")} gutter={isLapTop ? [33, 32] : [40, 32]}>
        {_map(data, (item) => {
          return (
            <Col key={item.id} span={8} lg={6} className={styles.marginCol}>
              <PostCard
                setRefreshFeed={setRefreshProfile}
                key={item.id}
                item={item}
                handleLikeUnlike={handleLikeUnlike}
                isProfile={isProfile}
                nickname={nickname}
              />
            </Col>
          )
        })}
      </Row>
    )
  }
  return (
    <>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 320: 1, 474: 1, 750: 2, 1024: 2 }}
      >
        <Mansory gutter={24} className="mansoryClassFavoritesFeed">
          {_map(data, (item) => {
            return (
              <PostCard
                setRefreshFeed={setRefreshProfile}
                key={item.id}
                item={item}
                handleLikeUnlike={handleLikeUnlike}
                isProfile={isProfile}
                nickname={nickname}
                className="postCard"
              />
            )
          })}
        </Mansory>
      </ResponsiveMasonry>
    </>
  )
}

export default PostFeed
