import { MailOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { getWatermarkURL } from "@utils/path"
import { Avatar, Button, Col, Divider, Row } from "antd"
import cx from "classnames"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import styles from "../style.module.less"

const Header = ({
  isMobile,
  isDesktop,
  data,
  followers,
  handleFollowers,
  handleUnfollowers,
  imFollowing,
  deleteButton,
}) => {
  const {
    urlProfile,
    urlCoverPage,
    name,
    nickname,
    type,
    location,
    likes,
    description,
    twitter,
    instagram,
    publicEmail,
    phoneNumber,
    visibleWhatsapp,
  } = data
  const { authSite } = useStores()
  const { user } = authSite
  const [isClient, setIsClient] = useState(false)
  const intl = useIntl()

  const srcProfile = getWatermarkURL(urlProfile, user)
  const srcCoverPage = getWatermarkURL(urlCoverPage, user)
  const urlInstagram = `${process.env.URL_INSTAGRAM}/${instagram}`
  const urlTwitter = `${process.env.URL_TWITTER}/${twitter}`
  const urlWhatsapp = `${process.env.URL_WHATSAPP}=${phoneNumber}`
  const urlEmail = `${process.env.URL_MAIL}:${publicEmail}`

  const followerText = intl.formatMessage({ id: "followers" })
  const likesText = intl.formatMessage({ id: "likes" })

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return isMobile ? (
    <>
      <img className="w-100" src={srcCoverPage} />
      <Row
        justify="center"
        align="center"
        className={cx("w-100 mb72 header-profile", styles.header)}
      >
        <Col span={20}>
          <div className="w-100 flex flex-column items-center justify-center">
            <Avatar size={108} src={srcProfile} />
            <span
              className={cx(
                "mt8 b f32 mine-shaft lh-title",
                styles.nameProfile
              )}
            >
              {!nickname ? name : nickname}
            </span>
            <div className="flex flex-row mt8 items-center">
              <span className="f12 b comet">
                {translate(`profile.${type}`)}
              </span>
              <Divider type="vertical" className={styles.divider} />
              <span className="f12 fw5 dusty-gray">{location}</span>
            </div>
            <div className="flex flex-row mt24 items-center">
              <span className="mr24 f14 dusty-gray">
                <span className="mine-shaft b">
                  {followers < 1000 ? followers : `${followers / 1000}kå`}
                </span>
                {` ${followerText}`}
              </span>
              <span className="f14 dusty-gray">
                <span className="mine-shaft b">
                  {likes < 1000 ? likes : `${likes / 1000}k`}
                </span>
                {` ${likesText}`}
              </span>
            </div>
            <span className="b f20 mine-shaft mv24 tc">{description}</span>

            <Row className={styles.contactIcons}>
              {!twitter ? (
                <Col span={6} className="tc">
                  <Icon
                    name="TwitterInactive"
                    className={styles.socialInactiveIcon}
                  />
                </Col>
              ) : (
                <Col span={6} className="tc">
                  <a
                    href={!twitter ? "#" : urlTwitter}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.cursorDefault}
                  >
                    <Icon
                      name="TwitterInactive"
                      className={styles.socialActiveIcon}
                    />
                  </a>
                </Col>
              )}
              {!instagram ? (
                <Col span={6} className="tc">
                  <Icon
                    name="InstagramInactive"
                    className={styles.socialInactiveIcon}
                  />
                </Col>
              ) : (
                <Col span={6} className="tc">
                  <a
                    href={!instagram ? "#" : urlInstagram}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.cursorDefault}
                  >
                    <Icon
                      name="InstagramInactive"
                      className={styles.socialActiveIcon}
                    />
                  </a>
                </Col>
              )}
              {!phoneNumber || !visibleWhatsapp ? (
                <Col span={6} className="tc">
                  <Icon
                    name="WhatsappInactive"
                    className={styles.socialInactiveIcon}
                  />
                </Col>
              ) : (
                <Col span={6} className="tc">
                  <a
                    href={!phoneNumber ? "#" : urlWhatsapp}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.cursorDefault}
                  >
                    <Icon
                      name="WhatsappInactive"
                      className={styles.socialActiveIcon}
                    />
                  </a>
                </Col>
              )}
              {!publicEmail ? (
                <Col span={6} className="tc">
                  <MailOutlined className={styles.emailInactiveIcon} />
                </Col>
              ) : (
                <Col span={6} className="tc">
                  <a
                    href={!publicEmail ? "#" : urlEmail}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.cursorDefault}
                  >
                    <MailOutlined className={styles.emailActiveIcon} />
                  </a>
                </Col>
              )}
            </Row>
            <Row className="w-100" justify="center">
              <Col span={12}>
                {!deleteButton ? (
                  !imFollowing ? (
                    <Button
                      block
                      type="primary"
                      className={styles.followButton}
                      onClick={handleFollowers}
                    >
                      {translate("follow")}
                    </Button>
                  ) : (
                    <Button
                      className={styles.followingButton}
                      onClick={handleUnfollowers}
                    >
                      {translate("following")}
                    </Button>
                  )
                ) : (
                  <Button className={styles.followingButton} disabled>
                    {translate("following")}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <Row
      justify="space-between"
      align="top"
      className={cx("w-100 mv72 ph16 header-profile", styles.contentSize)}
    >
      <Col span={11}>
        <div className="w-100 flex flex-column">
          <div className="flex flex-row">
            <Avatar size={!isDesktop ? 108 : 114} src={srcProfile} />
            <div className="flex flex-column ml24">
              <span className={cx("mine-shaft fw6 lh-title", styles.name)}>
                {!nickname ? name : nickname}
              </span>
              <div
                className={cx("flex flex-row mv12 items-center", styles.type)}
              >
                <span className="b comet">{translate(`profile.${type}`)}</span>
                <Divider type="vertical" className={styles.divider} />
                <span className="fw5 dusty-gray" style={{ lineHeight: "1" }}>
                  {location}
                </span>
              </div>
              {isDesktop && (
                <div
                  className={cx(
                    "flex flex-row items-center",
                    styles.numberSize
                  )}
                >
                  <span className="mr24 f18 dusty-gray">
                    <span className="mine-shaft b">
                      {followers < 1000 ? followers : `${followers / 1000}k`}
                    </span>
                    {` ${followerText}`}
                  </span>
                  <span className="f18 dusty-gray">
                    <span className="mine-shaft b">
                      {likes < 1000 ? likes : `${likes / 1000}k`}
                    </span>
                    {` ${likesText}`}
                  </span>
                </div>
              )}
            </div>
          </div>
          {!isDesktop && (
            <div
              className={cx("flex flex-row items-center", styles.numberSize)}
            >
              <span className="mr24 f14 dusty-gray">
                <span className="mine-shaft b">
                  {followers < 1000 ? followers : `${followers / 1000}k`}
                </span>
                {` ${followerText}`}
              </span>
              <span className="f14 dusty-gray">
                <span className="mine-shaft b">
                  {likes < 1000 ? likes : `${likes / 1000}k`}
                </span>
                {` ${likesText}`}
              </span>
            </div>
          )}
          <span
            className={cx("b mine-shaft mv48 lh-title", styles.description)}
          >
            {description}
          </span>

          <Row className={styles.contactIcons}>
            {!twitter ? (
              <Col span={6} className="tc">
                <Icon
                  name="TwitterInactive"
                  className={styles.socialInactiveIcon}
                />
              </Col>
            ) : (
              <Col span={6} className="tc">
                <a
                  href={!twitter ? "#" : urlTwitter}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.cursorDefault}
                >
                  <Icon
                    name="TwitterInactive"
                    className={styles.socialActiveIcon}
                  />
                </a>
              </Col>
            )}
            {!instagram ? (
              <Col span={6} className="tc">
                <Icon
                  name="InstagramInactive"
                  className={styles.socialInactiveIcon}
                />
              </Col>
            ) : (
              <Col span={6} className="tc">
                <a
                  href={!instagram ? "#" : urlInstagram}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.cursorDefault}
                >
                  <Icon
                    name="InstagramInactive"
                    className={styles.socialActiveIcon}
                  />
                </a>
              </Col>
            )}
            {!phoneNumber || !visibleWhatsapp ? (
              <Col span={6} className="tc">
                <Icon
                  name="WhatsappInactive"
                  className={styles.socialInactiveIcon}
                />
              </Col>
            ) : (
              <Col span={6} className="tc">
                <a
                  href={!phoneNumber ? "#" : urlWhatsapp}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.cursorDefault}
                >
                  <Icon
                    name="WhatsappInactive"
                    className={styles.socialActiveIcon}
                  />
                </a>
              </Col>
            )}
            {!publicEmail ? (
              <Col span={6} className="tc">
                <MailOutlined className={styles.emailInactiveIcon} />
              </Col>
            ) : (
              <Col span={6} className="tc">
                <a
                  href={!publicEmail ? "#" : urlEmail}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.cursorDefault}
                >
                  <MailOutlined className={styles.emailActiveIcon} />
                </a>
              </Col>
            )}
          </Row>

          <Row className="w-100">
            <Col span={12} xl={8}>
              {!deleteButton ? (
                !imFollowing ? (
                  <Button
                    block
                    type="primary"
                    className={styles.followButton}
                    onClick={handleFollowers}
                  >
                    {translate("follow")}
                  </Button>
                ) : (
                  <Button
                    className={styles.followingButton}
                    onClick={handleUnfollowers}
                  >
                    {translate("following")}
                  </Button>
                )
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={12}>
        <div
          className={cx("relative flex h-100 items-center", styles.rectangle)}
        >
          <img
            className={cx("w-100 absolute right-0", styles.coverImage)}
            src={srcCoverPage}
          />
        </div>
      </Col>
    </Row>
  )
}

export default Header
