import { LoadingOutlined } from "@ant-design/icons"
import { ArrowLeftOutlined } from "@ant-design/icons"
import Gallery from "@components/Gallery"
import LikedModels from "@components/likedModels"
import PostFeed from "@components/PostFeed"
import SearchModel from "@components/searchModel"
import { useStores } from "@hooks/use-stores"
import ListEssay from "@modules/Essay/Site/components/ListEssay"
import translate from "@src/i18n"
import { getParamsId } from "@utils/path"
import { Col, Row, Tabs } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import { getWindow } from "../../utils/getWindow"
import Header from "./components/Header"
import styles from "./style.module.less"

const { TabPane } = Tabs

const Profile = ({
  empty = false,
  refreshProfile,
  setRefreshProfile,
  isProfile = true,
}) => {
  const [gallery, setGallery] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const [postData, setPostData] = useState(null)
  const [dataContact, setDataContact] = useState(null)
  const [totalPosts, setTotalPosts] = useState()
  const [essayData, setEssayData] = useState(null)
  const [totalEssays, setTotalEssays] = useState()
  const [isClient, setIsClient] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
  })
  const [paginationEssay, setPaginationEssay] = useState({
    current: 1,
    pageSize: 12,
  })
  const [isBottom, setIsBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState("1")
  const [followers, setFollowers] = useState(0)
  const [imFollowing, setImFollowing] = useState(false)
  const [deleteButton, setDeleteButton] = useState(false)

  const { profile } = useStores()
  const {
    getProfileById,
    getAllPostProfile,
    getContactById,
    getEssayProfile,
    postNewFollow,
    deletedFollowers,
  } = profile

  const isMobile = useMedia({
    maxWidth: 767,
  })

  const isTablet = useMedia({
    maxWidth: 1023,
  })

  const isDesktop = useMedia({
    minWidth: 1024,
  })

  const isDesktopLarge = useMedia({
    minWidth: 1366,
  })

  const data = {
    urlProfile: _get(profileData, "urlProfile", ""),
    urlCoverPage: _get(profileData, "urlCoverPage", ""),
    name: _get(profileData, "name", ""),
    nickname: _get(dataContact, "nickname", ""),
    type: _get(profileData, "type", 1),
    location: `${_get(profileData, "city", "")}, ${_get(
      profileData,
      "state",
      ""
    )}`,
    description: _get(profileData, "describe", ""),
    likes: _get(profileData, "likes", 0),
    visibleWhatsapp: _get(profileData, "visibleWhatsapp", ""),
    instagram:
      profileData && profileData.instagram ? profileData.instagram : "",
    twitter: profileData && profileData.twitter ? profileData.twitter : "",
    publicEmail:
      dataContact && dataContact.publicEmail ? dataContact.publicEmail : "",
    phoneNumber:
      dataContact && dataContact.phoneNumber ? dataContact.phoneNumber : "",
  }

  useEffect(() => {
    handleGetProfileById()
    handleGetDataById()
  }, [])

  useEffect(() => {
    handleGetAllPostProfile()
  }, [profileData])

  useEffect(() => {
    setIsClient(true)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (isBottom && postData && postData.length && currentTab === "1") {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: prevState.pageSize + 12,
      }))
    }

    if (isBottom && essayData && essayData.length && currentTab === "2") {
      setPaginationEssay((prevState) => ({
        ...prevState,
        pageSize: prevState.pageSize + 12,
      }))
    }
  }, [isBottom])

  useEffect(() => {
    if (postData && postData.length < totalPosts && currentTab === "1") {
      handleGetAllPostProfile()
    }
    if (essayData && essayData.length < totalEssays && currentTab === "2") {
      handleGetEssayProfile()
    }
  }, [pagination, paginationEssay])

  useEffect(() => {
    handleGetAllPostProfile()
    handleGetEssayProfile()
  }, [profileData])

  useEffect(() => {
    if (refreshProfile) {
      setPostData(null)
      handleGetAllPostProfile()
      setRefreshProfile(false)
    }
  }, [refreshProfile])

  const handleGetProfileById = async () => {
    const id = getParamsId()
    const response = await getProfileById(id)

    setProfileData(response)
    setFollowers(response.followers)
    setImFollowing(response.imFollowing)

    const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))
    const idProfileLoged = _get(user, "id", "")

    if (response.id === idProfileLoged) {
      setDeleteButton(true)
    }
  }

  const handleGetDataById = async () => {
    const id = getParamsId()
    const response = await getContactById(id)
    setDataContact(response)
  }

  const handleGetAllPostProfile = async () => {
    setLoading(true)

    const affiliatedId = getParamsId()
    const response = await getAllPostProfile(affiliatedId, pagination)

    const posts = _get(response, "data", [])
    const totalPosts = _get(response, "total", 0)

    setPostData(posts)
    setTotalPosts(totalPosts)
    setLoading(false)
    setIsBottom(false)
  }

  const handleGetEssayProfile = async () => {
    setLoading(true)

    const affiliatedId = getParamsId()
    const response = await getEssayProfile(affiliatedId, paginationEssay)

    const essays = _get(response, "data", [])
    const totalEssays = _get(response, "total", 0)

    setEssayData(essays)
    setTotalEssays(totalEssays)
    setLoading(false)
    setIsBottom(false)
  }

  const handleFollowers = async () => {
    const affiliatedId = getParamsId()
    const { data } = await postNewFollow(affiliatedId)

    if (data.success === true) {
      setFollowers(data.followers)
      setImFollowing(true)
    }
  }

  const handleUnfollowers = async () => {
    const affiliatedId = parseInt(getParamsId())

    const { data } = await deletedFollowers(affiliatedId)

    if (data.success === true) {
      setFollowers(data.followers)
      setImFollowing(false)
    }
  }

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 50

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleSearch = () => {
    if (!visibleModal) {
      setVisibleModal(true)
      return
    }

    setVisibleModal(false)
  }

  const handleClick = () => {
    setGallery((prev) => !prev)
  }

  const handleBack = () => {
    navigate("/feed")
  }

  const TabName = (props) => {
    const { name, quantity } = props

    return (
      <div className="flex flex-row items-baseline">
        <span className="mr4 fw6">{name}</span>
        <span className={styles.numberQuantity}>{quantity}</span>
      </div>
    )
  }

  if (!postData) {
    return (
      <div className={styles.loadingMore}>
        <p className="flex flex-column justify-center items-center">
          <p>
            <LoadingOutlined style={{ fontSize: "60px" }} />
          </p>
          {data && data.length ? null : translate("loadingProfile")}
        </p>
      </div>
    )
  }

  const post = true
  const essay = true

  if (!isClient) return null

  return isMobile ? (
    <>
      <Row className="mb72">
        <div
          className={cx("flex items-center w-100 absolute", styles.backHeader)}
        >
          <ArrowLeftOutlined
            height={18}
            width={21}
            className="mh16 mobileIcon"
            onClick={handleBack}
          />
        </div>
        <Header
          isMobile={isMobile}
          isDesktop={isDesktop}
          data={data}
          handleFollowers={handleFollowers}
          followers={followers}
          handleUnfollowers={handleUnfollowers}
          imFollowing={imFollowing}
          deleteButton={deleteButton}
        />
        {post || essay ? (
          <Tabs
            defaultActiveKey={post ? "1" : "2"}
            onChange={(key) => {
              setIsBottom(false)
              setCurrentTab(key)
            }}
          >
            <TabPane
              tab={
                <TabName
                  name={translate("publications")}
                  quantity={totalPosts ? totalPosts : 0}
                />
              }
              key="1"
            >
              {!empty ? (
                <PostFeed
                  data={postData}
                  recommendedProfiles={false}
                  isProfile={isProfile}
                  nickname={data.nickname}
                />
              ) : (
                <Row justify="center" className="mv48">
                  <Col span={16} className="tc">
                    <span className="tc f14 f18-ns b scorpion-main">
                      {translate("profile.emptyStatePosts")}
                    </span>
                  </Col>
                  <Col span={24} className="mt72">
                    <LikedModels handleSearch={handleSearch} />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane
              tab={
                <TabName
                  name={translate("essays")}
                  quantity={totalEssays ? totalEssays : 0}
                />
              }
              key="2"
            >
              {!empty ? (
                <Row className="w-100 mv24">
                  {gallery ? (
                    <Gallery handleClick={handleClick} />
                  ) : (
                    <Col span={24}>
                      <ListEssay
                        data={essayData}
                        mostLiked={false}
                        handleClick={handleClick}
                        isProfile={isProfile}
                      />
                    </Col>
                  )}
                </Row>
              ) : (
                <Row justify="center" className="mv48">
                  <Col span={20} className="tc">
                    <span className="tc f14 f18-ns b scorpion-main">
                      {translate("profile.emptyStateEssays")}
                    </span>
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>
        ) : (
          <Row justify="center" className="mv32">
            <Col span={20} className="tc">
              <span className="tc f14 f18-ns b scorpion-main">
                {translate("profile.emptyState")}
              </span>
            </Col>
            <Col span={24} className="mv32">
              <LikedModels handleSearch={handleSearch} />
            </Col>
          </Row>
        )}
        <SearchModel visible={visibleModal} handleSearch={handleSearch} />
      </Row>
    </>
  ) : (
    <>
      <Row className={isTablet ? "mb72" : ""}>
        {isTablet && (
          <div
            className={cx(
              "flex items-center w-100 absolute",
              styles.backHeader
            )}
          >
            <ArrowLeftOutlined
              height={18}
              width={21}
              className="mh16 mobileIcon"
              onClick={handleBack}
            />
            <span className="f16 fw6 mine-shaft mv16 mr-auto">
              {translate("profile")}
            </span>
          </div>
        )}

        <Header
          isMobile={isMobile}
          isDesktop={isDesktop}
          isDesktopLarge={isDesktopLarge}
          data={data}
          handleFollowers={handleFollowers}
          followers={followers}
          handleUnfollowers={handleUnfollowers}
          imFollowing={imFollowing}
          deleteButton={deleteButton}
        />
        {post || essay ? (
          <Tabs
            defaultActiveKey={post ? "1" : "2"}
            onChange={(key) => {
              setIsBottom(false)
              setCurrentTab(key)
            }}
          >
            <TabPane
              tab={
                <TabName
                  name={translate("publications")}
                  quantity={totalPosts ? totalPosts : 0}
                />
              }
              key="1"
            >
              {!empty ? (
                <>
                  <PostFeed
                    data={postData}
                    nickname={data.nickname}
                    recommendedProfiles={false}
                    isProfile={isProfile}
                  />
                  {loading ? (
                    <div className={styles.loadingMore}>
                      <p className="flex flex-column justify-center items-center">
                        <p>
                          <LoadingOutlined style={{ fontSize: "60px" }} />
                        </p>
                        {postData && postData.length
                          ? translate("loadingMorePosts")
                          : translate("loadingPosts")}
                      </p>
                    </div>
                  ) : null}
                </>
              ) : (
                <Row justify="center" className="mt24">
                  <Col span={20} className="tc">
                    <span className="tc f14 f20-ns b scorpion-main">
                      {translate("profile.emptyStatePosts")}
                    </span>
                  </Col>
                  <Col span={24} className="mv72">
                    <LikedModels handleSearch={handleSearch} />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane
              tab={
                <TabName
                  name={translate("essays")}
                  quantity={totalEssays ? totalEssays : 0}
                />
              }
              key="2"
            >
              {!empty ? (
                <>
                  <Row className="w-100">
                    {gallery ? (
                      <Gallery handleClick={handleClick} />
                    ) : (
                      <Col span={24}>
                        <ListEssay
                          data={essayData}
                          mostLiked={false}
                          handleClick={handleClick}
                          isProfile={isProfile}
                        />
                      </Col>
                    )}
                  </Row>
                  {loading ? (
                    <div className={styles.loadingMore}>
                      <p className="flex flex-column justify-center items-center">
                        <p>
                          <LoadingOutlined style={{ fontSize: "60px" }} />
                        </p>
                        {essayData && essayData.length
                          ? translate("loadingMoreEssays")
                          : translate("loadingEssays")}
                      </p>
                    </div>
                  ) : null}
                </>
              ) : (
                <Row justify="center" className="mt24">
                  <Col span={20} className="tc">
                    <span className="tc f14 f20-ns b scorpion-main">
                      {translate("profile.emptyStateEssays")}
                    </span>
                  </Col>
                  <Col span={24} className="mt72">
                    <LikedModels handleSearch={handleSearch} />
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>
        ) : (
          <Row justify="center" className="mt72">
            <Col span={20} className="tc">
              <span className="tc f14 f20-ns b scorpion-main">
                {translate("profile.emptyState")}
              </span>
            </Col>
            <Col span={24} className="mt72">
              <LikedModels handleSearch={handleSearch} />
            </Col>
          </Row>
        )}
        <SearchModel visible={visibleModal} handleSearch={handleSearch} />
      </Row>
    </>
  )
}

export default Profile
